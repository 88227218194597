import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ActivatedRoute, RouterModule } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Document, getDocumentIcon, listReasons } from 'src/app/models/documents';
import { MessagesData, getDefaultMessages } from 'src/app/models/messages';
import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';
import { SupportChatService, THEME } from 'src/app/services/support-chat.service';

@UntilDestroy()
@Component({
  selector: 'app-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyProgressSpinnerModule
  ]
})
export class InformationsComponent implements OnInit, OnDestroy {
  title: string;
  body: string;
  notice: string;
  shortId: string;
  document: Document;
  reasons: string[];
  svgIcon: string;
  icon: string;

  constructor(
    private supportChatService: SupportChatService,
    private messagesService: MessagesService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.supportChatService.setTheme(THEME.dark);
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.shortId = this.shortId || params.shortId;
      });

    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((data: { resolved: Document }) => {
        this.document = data?.resolved;
        this.reasons = listReasons(this.document);
        const { icon, svgIcon } = getDocumentIcon(this.document?.status);
        this.icon = icon;
        this.svgIcon = svgIcon;
        this.shortId = this.shortId || this.document?.short_id;
        this.buildMessages();
      });
  }

  buildMessages(): void {
    const { title, body, notice } = getDefaultMessages(this.document?.status);

    this.messagesService.getConfirmationMessage(
      this.document?.status,
      this.document?.organization_type.type.id
    ).subscribe({
      next: (data: MessagesData) => {
        this.title = data.messages[0]?.title || title;
        this.body = data.messages[0]?.body || body;
        this.notice = data.messages[0]?.notice || notice;
      },
      error: () => {
        this.title = title;
        this.body = body;
        this.notice = notice;
      }
    });
  }

  ngOnDestroy(): void {
    this.supportChatService.setTheme(THEME.light);
  }
}
