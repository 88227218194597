import {inject} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Router, ResolveFn} from '@angular/router';

import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {SimpleDialogComponent} from 'src/app/components/simple-dialog/simple-dialog.component';
import {DynamicFormService} from 'src/app/services/dynamic-form.service';

export const DynamicFormsResolver: ResolveFn<
  string
> = (): Observable<string> => {
  const router = inject(Router);
  const dynamicFormService = inject(DynamicFormService);
  const dialog = inject(MatDialog);

  const state = router.getCurrentNavigation().extras.state;

  return dynamicFormService
    .findByCategoryIdAndTypeId(state.categoryId, state.organizationTypeId)
    .pipe(
      map((document) => ({
        documentId: document.id,
        organizationTypeId: state.organizationTypeId,
        organizationId: state.organizationId,
        personId: state.personId,
      })),
      catchError((err) => {
        SimpleDialogComponent.open(dialog, {
          okButton: 'OK',
          title: 'Não foi possível carregar o formulário escolhido.',
          content: 'Tente novamente mais tarde ou acione o suporte no botão Ajuda.'
        });

        router.navigate(['/t']);

        return of(undefined);
      })
    );
}
