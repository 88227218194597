import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import type {FactoryOpts} from 'imask';
import {Update, Send} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-input-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})

export class PhoneComponent implements OnInit {
  public phone: UntypedFormControl = new UntypedFormControl('', Validators.required);
  public phoneMask: FactoryOpts = {
    mask: [
      {mask: '(00) 00000-0000'},
      {mask: '+00 (00) 00000-0000'}
    ],
    scale: 0,
  };

  @Input() set value($value: string) {
    this.phone.setValue($value);

    if ($value) {
      this.updated.emit(this.buildAnswer($value));
    }
  }

  @Input() public placeholder: string;
  @Input() public reference: string;
  @Output() private updated: EventEmitter<Update> = new EventEmitter<Update>();

  ngOnInit(): void {
    this.phone.valueChanges
      .subscribe((value: string) => this.updated.emit(this.buildAnswer(value)));
  }

  private buildAnswer(value: string): Update {
    const update: Update = {
      valid: this.phone.value.length && (this.phone.value.length === 15 || this.phone.value.length === 19),
      answered: {
        label: value,
        send: {
          value
        }
      }
    };

    if (this.reference) {
      (update.answered.send as Send).reference = this.reference;
    }

    return update;
  }
}
