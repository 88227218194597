import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';

import {CompanyService} from '../services/company.service';

export const TermsAcceptedGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const companyService = inject(CompanyService);

  if (!companyService.rulesAndTermsAccepted()) {
    return router.createUrlTree(['/s/termos']);
  }

  return true;
}
