<div
  class="file__modal flex-100 d-flex flex-column justify-content-center align-items-stretch"
  [ngClass]="{ 'framed': !!(fileDialog.okButton && fileDialog.noButton) }"
>
  <div class="frame flex-100">
    <app-file-render
      class="file-render"
      [fileData]="fileDialog.fileData"
    ></app-file-render>
    <div class="border-color-primary border border--top border--left"></div>
    <div class="border-color-primary border border--top border--right"></div>
    <div class="border-color-primary border border--bottom border--left"></div>
    <div class="border-color-primary border border--bottom border--right"></div>
  </div>
  <div class="rules">
    <p>Antes de continuar verifique se:</p>
    <ul class="rules__list">
      <li class="rules__item"><strong>A foto não está borrada</strong></li>
      <li class="rules__item"><strong>O documento todo está dentro das bordas</strong></li>
    </ul>
  </div>
  <div *ngIf="!!fileDialog.okButton || !!fileDialog.noButton" class="container d-flex justify-content-space-between align-items-end gap-16">
    <button
      mat-stroked-button
      class="closecare-button primary-border flex-1-1-100"
      color="primary"
      (click)="redo()"
      [attr.aria-label]="fileDialog.noButton"
    >
      {{fileDialog.noButton}}
    </button>
    <button
      mat-raised-button
      cdkFocusInitial
      data-test="confirmation"
      class="closecare-button primary-bg flex-1-1-100"
      *ngIf="fileDialog.okButton"
      (click)="confirm()"
      [attr.aria-label]="fileDialog.okButton"
    >
      {{fileDialog.okButton}}
    </button>
  </div>
</div>
<button
  mat-mini-fab
  class="close"
  color="primary"
  aria-label="Fechar"
  *ngIf="!fileDialog.okButton && !fileDialog.noButton"
  (click)="redo()"
>
  <mat-icon>close</mat-icon>
</button>
