import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {formatISO, addDays} from 'date-fns';
import type {FactoryOpts} from 'imask';
import {Update} from '../../../../shared/models/chat.model';

@Component({
  selector: 'app-input-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})

export class NumberComponent implements OnInit {
  public number: UntypedFormControl = new UntypedFormControl('', Validators.required);
  public numberMask: FactoryOpts = {
    mask: Number,
    scale: 0,
  };

  @Input() public placeholder: string;
  @Input() public dependence: string;
  @Input() public reference: string;
  @Output() private updated: EventEmitter<Update> = new EventEmitter<Update>();

  ngOnInit(): void {
    this.number.valueChanges
      .subscribe((value: string) => this.updated.emit(this.buildAnswer(value)));
  }

  private buildAnswer(label: string): Update {
    let value: string = label;

    if (this.reference === 'expiration_date') {
      value = formatISO(addDays(new Date(this.dependence), Number(label)));
    }

    return {
      valid: this.number.value > 0,
      answered: {
        label,
        send: {
          value,
          reference: this.reference
        }
      }
    };
  }
}
