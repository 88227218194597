<div class="container center d-flex flex-column justify-content-space-between align-items-center gap-16">
  <ng-container *ngIf="!!title; else loading">
    <div>
      <h1>
        {{ title }}
      </h1>
      <div class="description">
        {{ body }}
      </div>
    </div>
  </ng-container>

  <div>
    <button mat-button class="closecare-button light" [routerLink]="['/c']">
      Fechar
    </button>
  </div>
  <ng-template #loading>
    <mat-progress-spinner mode="indeterminate" color="accent" diameter="32"></mat-progress-spinner>
  </ng-template>
</div>
