import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';

import {ChatService} from '../services/chat.service';

export const CodeGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const chatService = inject(ChatService);

  const data = chatService.data;

  if (!data.phone && !data.email) {
    return router.navigate(['/s/validacao'], {state: {redirect: state.url}});
  }

  return true;
}
