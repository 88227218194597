import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';

import {SickNoteService} from '../services/sick-note.service';

export const FileExistsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const sickNoteService = inject(SickNoteService);

  if (!sickNoteService.fileExists()) {
    return router.createUrlTree(['/']);
  }

  return true;
}
