import {inject} from '@angular/core';
import {ResolveFn} from '@angular/router';

import {Observable, forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';

import {DocumentTypesService} from 'src/app/services/document-types.service';
import {DocTypeService} from 'src/app/services/doc-type.service';
import {DocumentCategories, DocumentCategory, DocumentTypeList} from './entities/document-types.entity';

export const DocumentTypesResolver: ResolveFn<
  DocumentTypeList
> = (): Observable<DocumentTypeList> => {
  const documentTypesService = inject(DocumentTypesService);
  const docTypeService = inject(DocTypeService);

  return forkJoin({
    sick_note_types: docTypeService.getDocTypes(),
    document_types: documentTypesService.getDocumentsTypes(),
  })
  .pipe(map(({ sick_note_types, document_types }) => {
    if (sick_note_types.length === 0) {
      return document_types;
    } else {
      return {
        categories: [{
          id: 1,
          label: DocumentCategories.SICK_NOTE,
          types: sick_note_types.map((sick_note_type) => ({
            label: sick_note_type.name,
          }))
      } as DocumentCategory].concat(document_types.categories)
      };
    }
  }));
}
