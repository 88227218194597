import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';

import {DocTypeService} from '../services/doc-type.service';

export const DocTypeGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const docTypeService = inject(DocTypeService);

  if (!docTypeService.selected) {
    return router.createUrlTree(['/c']);
  }

  return true;
}
