import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { SAMLAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { Company } from 'src/app/services/company.service';
import { LocalStorageService, SignInMethods, StorageKeys } from 'src/app/services/local-storage.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { LoggedUserComponent } from 'src/app/components/logged-user/logged-user.component';

@Component({
  selector: 'app-company-home',
  templateUrl: './company-home.component.html',
  styleUrls: ['./company-home.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LoggedUserComponent
  ]
})
export class CompanyHomeComponent {
  company$: Observable<Company>;
  userOrganizationId: number | null;
  userName: string | null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private firebaseAuth: AngularFireAuth,
    private localStorageService: LocalStorageService,
    private organizationsService: OrganizationsService,
    @Inject('firebaseAuthSamlProject') private firebaseAuthSamlProject: AngularFireAuth,
  ) {
    const snackbarDuration = 30 * 1000; // 30 seconds
    this.company$ = this.route.data.pipe(
      map((data) => {
        if (data.resolved.showSnackbar) {
          this.matSnackBar.open(
            'Erro ao concluir o login. Fale com a sua empresa, ou acione o suporte no botão "Ajuda".',
            null,
            { duration: snackbarDuration }
          );
        }
        return data.resolved.company;
      }
    ));

    this.authUser$.subscribe(
      (user) => {
        user?.getIdTokenResult().then(
          (token) => {
            this.userOrganizationId = token?.claims.organization_id;
            this.userName = token?.claims.name;
          }
        );
      }
    );

    if ('redirect' in history.state) {
      this.localStorageService.set(StorageKeys.REDIRECT_URL, history.state['redirect']);
    }
  }

  get authUser$() {
    return this.authService.user$;
  }

  logout() {
    return this.authService.logout();
  }

  next() {
    this.router.navigate(['/s/validacao']);
  }

  login(loginType: string, organizationId: number) {
    if (loginType === 'saml') {
      return this.organizationsService.getProvider(organizationId).subscribe(
        (provider) => {
          const app = firebase.app('firebase-saml-project-auth');
          app.auth().tenantId = provider.auth_tenant_id;
          this.localStorageService.set(StorageKeys.SIGN_IN_METHOD, SignInMethods.SAML);
          // Se não tiver nenhum redirect programado
          if (!this.localStorageService.get(StorageKeys.REDIRECT_URL)) {
            // Adicionamos um redirect para /s/validacao
            this.localStorageService.set(StorageKeys.REDIRECT_URL, '/s/validacao');
          }
          return forkJoin({
            b2cLogout: this.firebaseAuth.signOut(),
            b2bLogin: this.firebaseAuthSamlProject.signInWithRedirect(new SAMLAuthProvider(provider.provider_id)),
          });
        }
      );
    }
    this.router.navigate(['/s/login']);
  }
}
