<div class="parent d-flex justify-content-center align-items-center">
  <div class="btns btns--left d-flex flex-column justify-content-space-between align-items-center">
    <mat-icon (click)="operate('h', '+')">keyboard_arrow_up</mat-icon>
    <mat-icon (click)="operate('h', '-')">keyboard_arrow_down</mat-icon>
  </div>
  <mat-form-field class="hours flex-1-1-100" appearance="outline">
    <mat-label>Horas</mat-label>
    <input matInput type="number" [min]="minHours" [max]="maxHours" [formControl]="hourCtrl" [imask]="{mask: '00'}">
  </mat-form-field>
  <span class="dots">:</span>
  <mat-form-field class="minutes flex-1-1-100" appearance="outline">
    <mat-label>Minutos</mat-label>
    <input matInput type="number" [min]="minMinutes" [max]="maxMinutes" [formControl]="minutesCtrl" [imask]="{mask: '00'}">
  </mat-form-field>
  <div class="btns btns--right d-flex flex-column justify-content-space-between align-items-center">
    <mat-icon (click)="operate('m', '+')">keyboard_arrow_up</mat-icon>
    <mat-icon (click)="operate('m', '-')">keyboard_arrow_down</mat-icon>
  </div>
</div>

