import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import firebase from 'firebase/compat/app';

import { AuthService } from 'src/app/services/auth.service';
import { Company } from 'src/app/services/company.service';
import { SupportChatService, THEME } from 'src/app/services/support-chat.service';
import { LoggedUserComponent } from 'src/app/components/logged-user/logged-user.component';
import { HomeData } from './home-resolver.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatLegacyButtonModule,
    LoggedUserComponent,
    RouterModule
  ]
})

export class HomeComponent {
  authUser: firebase.User | null;
  company: Company | null;
  showImage: boolean;
  userName: string | null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private supportChatService: SupportChatService,
  ) {
    this.route.data.subscribe(
      (data) => {
        const { user, company } = data.userCompany as HomeData;
        this.authUser = user;
        this.company = company;
        user?.getIdTokenResult().then((token) => {
          this.userName = token.claims.name;
        });
        if (!company) {
          this.supportChatService.setTheme(THEME.dark);
        } else {
          this.supportChatService.setTheme(THEME.light);
          this.showImage = true;
        }
      }
    );
  }

  logout() {
    this.authUser = null;
    this.company = null;
    this.userName = null;
    this.supportChatService.setTheme(THEME.dark);
    return this.authService.logout();
  }

  next() {
    this.router.navigate(['/s/validacao']);
  }
}
