<div class="border-color-primary answer__field d-flex justify-content-stretch">
    <div class="flex-auto" *ngIf="answer?.type !== answerType.icd">
        <app-input-text
            *ngIf="answer?.type === answerType.text"
            [placeholder]="answer?.placeholder | keyReplace: replacers"
            [reference]="answer?.reference.key"
            (updated)="updated($event)"
        >
        </app-input-text>
        <app-input-code
            *ngIf="answer?.type === answerType.code"
            [placeholder]="answer?.placeholder | keyReplace: replacers"
            [reference]="answer?.reference.key"
            [prefix]="prefix"
            [ngClass]="{ 'answer__prefix': prefix !== undefined }"
            (updated)="updated($event)"
        >
        </app-input-code>
        <app-input-number
            *ngIf="answer?.type === answerType.number"
            [placeholder]="answer?.placeholder | keyReplace: replacers"
            [reference]="answer?.reference.key"
            [dependence]="dependence"
            (updated)="updated($event)"
        >
        </app-input-number>
        <app-input-email
            *ngIf="answer?.type === answerType.email"
            [placeholder]="answer?.placeholder | keyReplace: replacers"
            [reference]="answer?.reference.key"
            (updated)="updated($event)"
        >
        </app-input-email>
        <app-input-phone
            *ngIf="answer?.type === answerType.phone"
            [placeholder]="answer?.placeholder | keyReplace: replacers"
            [reference]="answer?.reference.key"
            (updated)="updated($event)"
        >
        </app-input-phone>
    </div>
    <div class="input__wrapper flex-auto d-flex justify-content-stretch align-items-center" *ngIf="answer?.type === answerType.icd">
        <app-input-icd class="d-flex justify-content-stretch align-items-center"
        [placeholder]="answer?.placeholder | keyReplace: replacers"
        [reference]="answer?.reference.key"
        (updated)="updated($event)"
    >
    </app-input-icd>
    </div>
    <button mat-button data-test="input-submit" class="answer__send" color="primary" [disabled]="!valid" (click)="sendAnswer.emit(answered)"
            aria-label="Enviar">
        <span class="material-icons answer__icon">send</span>
    </button>
</div>
