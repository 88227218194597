import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimePickerComponent} from './time-picker.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {IMaskModule} from 'angular-imask';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  declarations: [TimePickerComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    IMaskModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [TimePickerComponent]
})
export class TimePickerModule {}
