import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-design-tokens',
  templateUrl: './design-tokens.component.html',
  styleUrls: ['./design-tokens.component.scss'],
  standalone: true,
  imports: [ CommonModule ]
})
export class DesignTokensComponent {
  type: string = "sizes"
}
