import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, Router, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';

import {AuthService} from '../services/auth.service';

export const AuthorizedGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.user$.pipe(
    take(1),
    map((user) => Boolean(user)),
    tap((valid) => {
      if (!valid) {
        router.navigate(['s/login'], {state: {redirect: state.url}});
      }
    })
  );
}
