<div #scroller class="scroller flex-auto">
    <div class="messages d-flex flex-column justify-content-flex-end">
        <app-message
          #message
          *ngFor="let message of messages; index as i"
          [ngClass]="{'message.image': 'image__loader'}"
          [from]="message.from"
          [body]="message.body | keyReplace: message.replacers"
          [warning]="message.warning"
          [fileData]="message.fileData"
          [breakStrong]="message.breakStrong"
          [edition]="i === edition"
          (edit)="edit(i)"
        ></app-message>
        <app-message [from]="from.bot" *ngIf="typing"></app-message>
    </div>
</div>
<div class="answer">
    <div [@answer] *ngIf="!typing">
        <app-answer-inputs
            *ngIf="!!script.answer?.placeholder"
            [answer]="script.answer"
            [dependence]="dependence"
            [replacers] = "message.replacers"
            [prefix]="prefix"
            (sendAnswer)="sendAnswer($event)"
            (layoutUpdated)="moveScroll()"
        >
        </app-answer-inputs>
        <app-answer-buttons
            *ngIf="!!script.answer?.button || !!script.answer?.buttons"
            [answer]="script.answer"
            [dependence]="dependence"
            [pickers]="pickers"
            [cancel]="script.onCancel"
            [flowType]="flowType"
            [allowanceHours]="chatService.allowanceHours"
            (sendAnswer)="sendAnswer($event)"
        >
        </app-answer-buttons>
        <app-answer-cancel
            *ngIf="script.answer?.cancel"
            [label]="script.answer?.cancel"
            [cancel]="script.onCancel"
            [replacers] = "message.replacers"
            (sendAnswer)="sendAnswer($event)"
        >
    </app-answer-cancel>
    </div>
</div>
<div class="snackbar" *ngIf="requestError">
    <p class="snackbar__text">Ocorreu um falha na conexão. Verifique se você está conectado a internet e tente novamente.</p>
    <button mat-button class="snackbar__button" (click)="requestError()"
            aria-label="Tentar novamente">Tentar novamente</button>
</div>
<app-loading *ngIf="loading"
             title="Enviando"
             body="Normalmente demora alguns segundos">
</app-loading>
