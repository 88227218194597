<div class="message message__user d-flex justify-content-flex-end align-items-center" *ngIf="fromUser">
    <app-edit class="message__edit" *ngIf="edition" (edit)="edit.emit()"></app-edit>
    <div class="message__balloon">
        <p *ngIf="!fileData" [innerHtml]="body | safeHtml"></p>
        <app-file-render
          class="file-render message__file"
          *ngIf="!!fileData"
          [fileData]="fileData"
          [imgCover]="true"
        ></app-file-render>
    </div>
</div>
<div class="message message__bot d-flex justify-content-stretch align-items-flex-end" [ngClass]="{ 'message__bot--edit' : body && edition }" *ngIf="!fromUser">
    <span class="material-icons bot__icon">face</span>
    <div class="d-flex justify-content-flex-start align-items-center">
        <div class="message__balloon"
            *ngIf="body"
            [ngClass]="{
                'message__balloon--warning' : warning,
                'message__balloon--break__strong': breakStrong
            }"
            [innerHtml]="body | safeHtml">
        </div>
        <app-edit class="message__edit" *ngIf="body && edition" (edit)="edit.emit()"></app-edit>
        <div class="message__balloon" *ngIf="!body">
            <p class="bot__typing">
                <span class="typing__dots">
                    <span class="typing__dot"></span>
                    <span class="typing__dot"></span>
                    <span class="typing__dot"></span>
                </span>
                Digitando
            </p>
        </div>
    </div>
</div>
