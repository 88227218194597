<div class="container center d-flex flex-column justify-content-space-between align-items-center gap-16">
  <ng-container *ngIf="!!title; else loading">
    <div>
      <mat-icon [svgIcon]="svgIcon" class="status-icon">{{ icon }}</mat-icon>
      <h1>
        {{ title }}
      </h1>
      <p class="content" [innerHTML]="body"></p>
      <div class="description">
        <ul class="description__list" *ngIf="'fields' in document.specific || 'extras' in document.specific">
          <li class="description__item" *ngFor="let reason of reasons">
            <span>{{ reason }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <p class="content center">Seu protocolo é:</p>
      <h1>{{ shortId }}</h1>
    </div>
  </ng-container>

  <div>
    <p class="content" [innerHTML]="notice"></p>
    <button mat-stroked-button class="closecare-button light" [routerLink]="['/a', shortId]" aria-label="Ver ">
      Ver detalhes do documento
    </button>
  </div>
  <ng-template #loading>
    <mat-progress-spinner mode="indeterminate" color="accent" diameter="32"></mat-progress-spinner>
  </ng-template>
</div>
