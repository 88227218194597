<div class="input__prefix d-flex justify-content-center align-items-center" *ngIf="prefix !== undefined">{{ prefix }}</div>
<input
    required
    matInput
    data-test="input-code"
    class="input__field"
    autocomplete="off"
    name="code"
    [ngClass]="{ 'input__field--prefix': prefix !== undefined }"
    [imask]="codeMask"
    [placeholder]="placeholder"
    [formControl]="code"
>
