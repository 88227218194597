<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'sizes'">
    <h2>Sizes</h2>
    <hr />

    <div class="font-size-48">xxxx-large</div>
    <div class="font-size-40">xxx-large</div>
    <div class="font-size-32">xx-large</div>
    <div class="font-size-24">x-large</div>
    <div class="font-size-20">large</div>
    <div class="font-size-16">medium</div>
    <div class="font-size-14">small</div>
    <div class="font-size-13">x-small</div>
    <div class="font-size-12">xx-small</div>
  </ng-container>

  <ng-container *ngSwitchCase="'weights'">
    <h2>Weights</h2>
    <hr />

    <div class="font-weight-900 font-size-40">Black</div>
    <div class="font-weight-700 font-size-40">Bold</div>
    <div class="font-weight-500 font-size-40">Medium</div>
    <div class="font-weight-400 font-size-40">Regular</div>
  </ng-container>

  <ng-container *ngSwitchCase="'styles'">
    <h2>Styles</h2>
    <hr />

    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>
    <h5>Heading 5</h5>
    <div class="subtitle">Subtitle</div>
    <div class="label-1">Label 1</div>
    <div class="label-2">Label 2</div>
    <div class="body-1">Body 1</div>
    <div class="body-2">Body 2</div>
    <div class="caption">Caption</div>
  </ng-container>

  <ng-container *ngSwitchCase="'shadows'">
    <h2>Shadows</h2>
    <hr />

    <div class="circle box-shadow-soft m-24">soft</div>
    <div class="circle box-shadow-medium m-24">medium</div>
    <div class="circle box-shadow-strong m-24">strong</div>

    <h2>Gradients</h2>
    <hr />

    <div class="circle m-24"></div>
  </ng-container>
</ng-container>
