<div class="chat-history mb-16 px-32">
  <app-chat-history
    [chat]="chat"
    [typing]="typing$ | async"
    [step]="step"
    (undoEvent)="undo()"
    (openFilePickerEvent)="editFiles()"
  ></app-chat-history>
</div>

<div class="component-area mb-16 px-32" *ngIf="response$ | async as response">
  <ng-container *ngIf="(typing$ | async) === false">
    <form *ngIf="currentFormGroup" [formGroup]="currentFormGroup">
      <ng-container
        *ngIf="response.component"
        [ngSwitch]="response.component.type"
      >
        <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_TEXT">
          <app-input-wrapper
            [currentComponent]="response.component"
            [formControlName]="response.component.field"
          ></app-input-wrapper>
        </ng-container>

        <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_DATE">
          <app-input-wrapper
            [currentComponent]="response.component"
            [formControlName]="response.component.field"
          ></app-input-wrapper>
        </ng-container>

        <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_MONTH_PICKER">
          <app-input-wrapper
            [currentComponent]="response.component"
            [formControlName]="response.component.field"
          ></app-input-wrapper>
        </ng-container>

        <ng-container
          *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_TWO_BUTTONS"
        >
          <app-two-buttons
            [currentComponent]="response.component"
            [formControlName]="response.component.field"
          ></app-two-buttons>
        </ng-container>

        <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_CHILDCARE_DEPENDENT">
          <app-childcare-dependent
            [currentComponent]="response.component"
            [formControlName]="response.component.field"
          ></app-childcare-dependent>
        </ng-container>

        <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_CHILDCARE_INSTITUTION">
          <app-childcare-institution
            [currentComponent]="response.component"
            [formControlName]="response.component.field"
            [answers]="getAnswersSoFar()"
          ></app-childcare-institution>
        </ng-container>

        <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_BUTTON_LINK">
          <app-button-link [currentComponent]="response.component"></app-button-link>
        </ng-container>

        <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_FILE">
          <button
            class="
              font-size-16
              border-radius-24
              py-16
              white-font
              full-width
            "
            (click)="openFilePicker(response.component.field, response.component.imageType)"
          >
            Continuar
          </button>
          <div class="text-align-center mt-8" *ngIf="response.component.canSkipImage">
            <span>Não possui esse documento?</span>
            <button mat-button class="skip-image-button" (click)="skipCurrentImage(response.component)">Pular</button>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="ComponentEnum.COMPONENT_TYPE_PUSH_NOTIFICATIONS">
          <button
          class="
            font-size-16
            border-radius-24
            py-16
            white-font
            full-width
          "
          (click)="startPushNotificationsSetup(true, response.component)"
        >
          Continuar
        </button>
        <div class="text-align-center mt-8">
          <button mat-button class="skip-image-button" (click)="startPushNotificationsSetup(false, response.component)">Pular</button>
        </div>
        </ng-container>
      </ng-container>
    </form>
  </ng-container>

  <button
    type="button"
    class="
      font-size-16
      border-radius-24
      py-16
      white-font
      full-width
    "
    (click)="submit()"
    *ngIf="!response.component && (typing$ | async) === false"
  >
    Enviar
  </button>
</div>

<app-loading *ngIf="loading"
             title="Enviando"
             body="Normalmente demora alguns segundos">
</app-loading>
